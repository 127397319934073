console.info("Loaded .............. Script 2");

	var d = document;
	var codeList = new Array();
	var modeSelected;
	var MyScanner;
	var myWhatch;
	function myWatcher(){
		//myWhatch = setInterval(waitWatch,1000);
	};
	myWatcher();
	function waitWatch(){	
		let id = "tr[data-start]";
		let target = $(document).find(id);
		
		if($(target).length>0){
			//set dataTable on data Loaded
			if(!$(id).is('.initiate')){
				let f1 = $(target)[0];
				
				let pb = $(f1).find('.progress_bar');
				let tn = $(f1).find('.tn').val(); // Now(); tNow:TimeStamp
				let t0 = $(f1).find('.t0').val(); // Now(); tDebut:TimeStamp
				let idEtape = $(f1).find('.etape').val();
				
				//console.clear();
				if(tn && t0 && idEtape){
					//console.info("complet. Suivant");
					setProgress();
					//clearInterval(myWhatch);
				}else{
					
				}
			
				//$(id).addClass('initiate');
				
					//setProgress();
			}else{
				//console.warn('Already Initiate', "STOP setting DataTable");
				
			}
		}
	}
	
	
	const populate = ()=>{
		let target = d.querySelector('#codeList');
		target.innerHTML = "";
		if(codeList.length>0){
			target.innerHTML = "";
			let i = 0;
			for(let elem of codeList){
				let li = d.createElement('li');
				li.setAttribute('class','code'+i);
				li.innerText = elem;
				target.appendChild(li);
				i++;
			}
		}
	}
		
	var nbChk = 0;
	const checkList = (list,value)=>{
		let lenList = $(document).find('#nombre_code_barre').val(); //list.length;
		console.info("The List",list);
		
		if(list.includes(value)){
			/*let target = $(document).find(".code"+list.indexOf(value));
			console.log('target',target);
			target.append(' <i class="fa fa-check green-color"></i>').addClass('verified');
			if($(target).not('.verified')){
				nbChk++;
			}*/
			nbChk++;
			console.info("Checking", value, "Nb Checvk", nbChk);
		}
		
		
		//console.info("list: ",lenList, nbChk);
		
		if(lenList == nbChk){
			console.warn("Check Finished");
			clearTimeout('myCodeTimer');
			
			$(document).find('#qr-reader').fadeOut();
			
			d.querySelector('#qr-reader__dashboard').hidden = true;
			d.querySelector('#qr-reader__scan_region').hidden = true;
			
			$(document).find('#nbColis').prop('checked','true');
			
			let btnScannWrapper = $(document).find('#openScanner').parent();
			$(btnScannWrapper).fadeOut();
			MyScanner.html5Qrcode.stop();
			let btnCloseModal = $(document).find('#btnCloseModal');
			$(btnCloseModal).text('Términer');
			
		}
		
	}
	
	function FillList(value,obj){
		console.warn('Fill Mode',value);
		let hawb = $(document).find('#hawb');
		$(hawb).val(value);
		$(obj).val(value);
		
		/*if(value){
			if(codeList.includes(value)){
				console.warn("Existing",true);	
			}else{
				codeList.push(value);
			}
			populate();
		}*/
		//let i=0;
		
		/*for(let elem of codeList){
			let target = $(document).find('#code_barre'+i);
			let label = $(document).find('#labelCode'+i);
			//$(target).parent().prev().append(' <i class="fa fa-check green-color"></i>');
			$(target).val(elem);
				$(label).not('.verified').html($(label).html()+' <i class="fa fa-check green-color"></i>')
					.addClass('verified');
			/*let prev = $(target).prev('label');
			$(prev).append(' <i class="fa fa-check green-color"></i>');* /
			i++;
		}*/
		
	/*	let lenList = codelist.length;
		let nb_colis = $(document).find('#nb_colis').val();
		
		if(lenList == nb_colis){
			console.warn("Check Finished");
			clearTimeout('myCodeTimer');
		
			d.querySelector('#qr-reader').hidden = true;
			d.querySelector('#qr-reader__dashboard').hidden = true;
			d.querySelector('#qr-reader__scan_region').hidden = true;
			
			MyScanner.html5Qrcode.stop();
			
		}*/
		
			console.warn("Check Finished");
			clearTimeout('myCodeTimer');
		
			d.querySelector('#qr-reader').hidden = true;
			d.querySelector('#qr-reader__dashboard').hidden = true;
			d.querySelector('#qr-reader__scan_region').hidden = true;
			
			MyScanner.html5Qrcode.stop();
		
	}
	
    function docReady(fn) {
        // see if DOM is already available
        if (d.readyState === "complete"
            || d.readyState === "interactive") {
            // call on next available tick
            var myCodeTimer = setTimeout(fn, 1);
        } else {
            d.addEventListener("DOMContentLoaded", fn);
        }
    }
	
    //docReady(loadMe());
	
	function loadMe() {
		
		
		//console.warn('LoadMe Called...');
        var resultContainer = $(document).find('#qr-reader-results');
		let format = $(document).find('#results-format');
		let qr_reader = $(document).find('#qr-reader');
        var lastResult, countResults = 0;
		
        function onScanSuccess(decodedText, decodedResult) {
            if (decodedText !== lastResult) {
                ++countResults;
                lastResult = decodedText;
                // Handle on success condition with the decoded message.
                console.warn("Mode Selected",modeSelected);
				if(modeSelected==2){
					FillList(decodedResult.result.text,$(document).find('#code_barre'));
				}
				if(modeSelected==1){
					checkList(codeList,decodedResult.result.text);
				}
				
				$(resultContainer).text(decodedResult.result.text);
				format = $(document).find('#results-format');
				$(format).text(decodedResult.result.format.formatName);
            }else{
				setTimeout(function(){
					 lastResult = "needNew";
				}, 500);
			}
        }
		
        MyScanner = new Html5QrcodeScanner("qr-reader", { fps: 10, qrbox: 320 });
        MyScanner.render(onScanSuccess);
		$(document).find('#qr-reader img[alt]').hide();
		$(document).find('#qr-reader__dashboard_section_swaplink').hide();
		$(document).find('#qr-reader__camera_permission_button').text('Activer Parmission Camera');
		let altImg = $(document).find('#qr-reader__scan_region');
		$(altImg).click(function(){
			$(document).find('#qr-reader__camera_permission_button').click();
		});
    }
	
	function startScanner(){
		//console.warn("Btn Clicked");
		docReady(loadMe());
		
		d.querySelector('#qr-reader').hidden = false;
		d.querySelector('#qr-reader__dashboard').hidden = false;
		d.querySelector('#qr-reader__scan_region').hidden = false;
		
		$(document).find('#btnChk').hide();
		$(document).find('#StopScann').hide();
	}
		
	$(document).on('click','#openScanner',function(){
		$($(document).find('#nombreCB')).css('display','block !important');
		startScanner();
		mode = $(this).data('mode');
		
		if(mode =="chkList"){
			console.warn('Mode is',mode);
			modeSelected = 1;
			let codeListBox = $(document).find('.barcode');
			for(value of codeListBox){
				if(codeList.includes($(value).val())){
					console.error("Existing");	
				}else{
					codeList.push($(value).val());
				}
			}
			populate();
			console.info(codeList);
		}
		
		if(mode == "addList"){
			modeSelected = 2;
		}
	});
	$(document).on('click','#btnChk',function(){
		MyScanner.render(onScanSuccess);
	});
	$(document).on('click','#StopScann',function(){
		MyScanner.html5Qrcode.stop();
	});
	
	/******************************************************************************/
	
	
	function setProgress(){
		let tr = $(document).find('tr[data-start]');
		var Cn = {'e1':[1,2,5],'e2':[2,5,10],'e3':[5,10,20],'e4':[10,20,30],'e5':[10,20,30],
					'e6':[10,20,30],'e7':[10,20,30],'e8':[10,20,30],'e9':[10,20,30],'e10':[10,20,30],
					'e11':[10,20,30],'e12':[10,20,30],'e13':[10,20,30],'e14':[10,20,30],'e15':[10,20,30],
					'e16':[10,20,30],'e17':[10,20,30],'e18':[10,20,30]
					};
		
		if($('tr[data-start]').length){
			//console.clear();
			$('tr[data-start]').each(function(index,elem){
				
				//console.log(elem);
				
				let pb = $(elem).find('.progress_bar');
				let tn = $(elem).find('.tn').val(); // Now(); tNow:TimeStamp
				let t0 = $(elem).find('.t0').val(); // Now(); tDebut:TimeStamp
				let idEtape = $(elem).find('.etape').val();
				let d1 = $(elem).find('.t1').val(); //:durre
				let d2 = $(elem).find('.t2').val(); //:durre
				let d3 = $(elem).find('.t3').val(); //:durre
				
				tn  = parseInt(tn) /60; //: to Minutes
				t0 = parseInt(t0)/60; //: to Minutes
				//console.info(tn);
			
				let etape = Cn['e'+idEtape]; // get t1,t2,t3 par etapes
				d3 = etape[2]; //get t3 val
				
				let fin = parseInt(t0) + d3; //Fin 
				let de = parseInt(tn) - parseInt(t0); //durre ecoulee : now() - tempDebut
				let r = parseInt(d3) - de; // duree restante
				let v = r * 60;
				
				
				console.warn("Now=",tn,"Debut: ",t0,"Durre:",d3,"min","ecoulée ",de, " fin=",fin,"restant=",r);
				
				
				if(r>0){
					let v = r * 60;
					v = parseInt(v);
					$(pb).css({'transition':'width '+v+'s linear','width':0}).addClass('onProgress');
				}else{
					$(pb).removeAttr('style').width('100%').addClass('finished');	
				}

			});
		}
}




