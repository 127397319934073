//import {Html5QrcodeScanner} from "html5-qrcode.min";
console.info("Loaded myScipt.js");
	var theDataSource = "";
	var myTimeInt = false;
	
	var dataSetter = function() {
		this.init= function() {
			console.info('Data Setter initialized');
			//waitDataTable();
		}
		
		this.setDataTable = function(obj,msg = ""){
			if(msg){console.warn(msg,obj);}
			//theDataSource = obj;
			anon();
		}
		this.showTime = function(msg=""){
			
			if(!myTimeInt){
				//console.info("Show the Time::dataSetter",msg);
				showTime();	
			}else{
				console.warn("dataSetter: Time already Shown");	
			}
		}
	}
	
	testMe = function(){
			alert('tested outer');
		}

//$(document).ready(function(){
	
	
	var myTimer;
	
	function anon(){
		//console.info("Loaded - myScipt.js");
		//console.warn("called from auto.............");
		myTimer = setInterval(waitDataTable,1000);
	};
	
	//Check if the table exist
	//and wait for data loaded on table
	function waitDataTable(){
		//console.warn('waitDataTable Called');
		let id = "#dataTable";
		let target = $(document).find(id);
		//console.info("target",target);
		let tr = $(target).find('tr');
		
		if($(tr).length>=2){
			//set dataTable on data Loaded
			if(!$(id).is('.initiate')){
				$(id).addClass('initiate');
				
				if($(target).is('.hasFilter')){
					myFilterSetter(id);	
				}
				setDataTable(id);
			}else{
				//console.warn('Already Initiate', "STOP setting DataTable");
				
			}
			clearInterval(myTimer);
		}else{
			clearInterval(myTimer);	
		}
		
	}
	
/*********************************************************************************/	
	var minDate;
	var maxDate;
	let myCurrentPage = window.location.hash;
	
	if(!myCurrentPage.includes('affichage')){
		$.fn.dataTable.ext.search.push(
			function( settings, data, dataIndex ) {
				var min = minDate.val();
				var max = maxDate.val();
				
				var date = new Date( data[0] );
		 
				if (
					( min === null && max === null ) ||
					( min === null && date <= max ) ||
					( min <= date   && max === null ) ||
					( min <= date   && date <= max )
				) {
					return true;
				}
				return false;
			}
		);
	}
	

	function filterGlobal(id) {
		$('#dataTable')
			.DataTable()
			.search($('#global_filter').val(), true, true)
			.draw();
	}
//	 .search($('#global_filter').val(), $('#global_regex').prop('checked'), true)
	function filterColumn(i) {
		$('#dataTable')
			.DataTable()
			.column(i)
			.search(
				$('#col' + i + '_filter').val(),true,true
			)
			.draw();
//$('#col' + i + '_filter').val(),$('#col' + i + '_regex').prop('checked'),true
	}

/********************************************************************************/
	
	
	const myFilterSetter = (wrapper = "")=>{
		let target = $("<div>",{'id':'filterWrapper','class':'card card-filter'});
		let target_head = $("<div>",{'class':'card-header collapsed', 'data-toggle':'collapse','data-target':'#collapseFilter'});
		let target_title = '<i class="fa fa-search-plus"></i> Recherche avancée <span class="expansion-indicator float-right highlight"><i class="fa fa-angle-up"></i></span>';
		let target_body_wrapper = $('<div>',{'id':'collapseFilter','class':'collapse'});
		let target_body = $('<div>',{'class':'card-body'});
		let theForm = '<table cellpadding="3" cellspacing="0" border="0" style="width: 80%; margin: 0 auto 2em auto;"><thead>  <tr>    <th width="19%">&nbsp;</th>    <th width="81%">Recherche</th>  </tr></thead><tbody>  <tr id="filter_global">    <td>Recherche Global</td>    <td align="center"><input type="text" class="form-control global_filter" id="global_filter"></td>  </tr>  <tr id="filter_col2" data-column="2">    <td>Num Dossier</td>    <td align="center"><input type="text" class="form-control column_filter" id="col2_filter"></td>  </tr>  <tr id="filter_col3" data-column="3">    <td>Etat</td>    <td align="center"><input type="text" class="form-control column_filter" id="col3_filter"></td>  </tr>  <tr id="filter_col4" data-column="4">    <td>LTA-HAWB</td>    <td align="center"><input type="text" class="form-control column_filter" id="col4_filter"></td>  </tr>  <tr id="filter_col5" data-column="5">    <td>Type</td>    <td align="center"><input type="text" class="form-control column_filter" id="col5_filter"></td>  </tr></tbody></table><table border="0" cellspacing="5" cellpadding="5"><tbody>  <tr>    <td>Minimum date:</td>    <td><input type="text" id="min" name="min"></td>  </tr>  <tr>    <td>Maximum date:</td>    <td><input type="text" id="max" name="max"></td>  </tr></tbody></table>';
		target_body.append(theForm);
		target_body_wrapper.append(target_body);
		target_head.append(target_title);
		target.append(target_head);
		target.append(target_body_wrapper);
		
		if(wrapper!=""){
			let theParent = $(wrapper).parent();	
			$(theParent).prepend(target);
		}else{
			console.warn('Filter', "No Container defined");	
		}
		
		
		/*********************************/
			minDate = new DateTime($('#min'), {
				format: 'DD-MM-YYYY'
			});
			maxDate = new DateTime($('#max'), {
				format: 'DD-MM-YYYY'
			});
		
			theDate =  new DateTime($('#col0_filter'), {
				format: 'DD-MM-YYYY'
			});
		
		
		
		/**************************************/
		
		
	}
	/*******************************/
	var table;
	$(document).on('change','#min, #max', function () {
		//console.info(this))	;
		if(this.val()){
        	table.draw();
		}else{
			
			//$('#max').val('0')	;
		}
    }); 
	
		//$('#example').DataTable();
	 
		$(document).on('keyup click','input.global_filter', function () {
			filterGlobal();
		});
	 
		$(document).on('keyup click change','input.column_filter', function () {
			filterColumn($(this).parents('tr').attr('data-column'));
		});
		
		$(document).on('keyup','#mytest', function(){
			$('#dataTable')
			.DataTable()
			.column(2)
			.search($(this).val(),true,true
			)
			.draw();
		});
	/*********************************************/	

	
	function setDataTable(id,parms = ""){
		let target = $(document).find(id);
		console.info("Datatable Initate");
		 table = $(target).DataTable({
			search: {
           	 return: false,
        	},
			order: [[0, 'desc']],
			paging: true,
			ordering: true,
			info: true,
			language: {
				lengthMenu: 'Afficher _MENU_ lignes par page',
				zeroRecords: 'Ligne introuvable',
				info: 'Affichage de _PAGE_ sur _PAGES_ pages',
				infoEmpty: 'Aucun résultat',
				infoFiltered: '(filter sur _MAX_ lignes)',
				search: 'Recherche rapide'
				
        	}
		});
	}
	
/***** Horloge Numerique ************/
//	var months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
	
var months = ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Decembre"];
//var days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"];
var days = ["Dimanche","Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi","Dimanche"];

function showTime(){
    let date = new Date();
    let h = date.getHours(); // 0 - 23
    let m = date.getMinutes(); // 0 - 59
    let s = date.getSeconds(); // 0 - 59
	let meridian = "AM";
	
	let dt = date.getDate();
	let day = days[date.getDay()];
	let month = months[date.getMonth()];
	let year = date.getFullYear();
    
    if(h == 0){
        h = 12;
    }
    
    if(h > 12){
        h = h - 12;
        meridian = "PM";
		$(document).find('.clockcase2').addClass('pmNow');
    }
    
    h = (h < 10) ? "0" + h : h;
    m = (m < 10) ? "0" + m : m;
    s = (s < 10) ? "0" + s : s;
	dt = (dt<10) ? "0" + dt : dt;
    
    var time = '<div class="theDate">'+day +", "+ dt +" "+ month +" "+year+'</div>'
				+'<div class="theHour">'+ h + ":" + m + ":" + s + " " + meridian+'</div>';
    /*document.getElementById("MyClockDisplay").innerText = time;
    document.getElementById("MyClockDisplay").textContent = time;*/
	$('#MyClockDisplay').html(time);
    
    myTimeInt = setTimeout(showTime, 1000);
}
/***********} end Horloge Numerique ******/



