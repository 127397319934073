console.info("[ ]DashScreen Loaded[ ]");

if((window.location.hash).includes('affichage') && !(window.location.hash).includes('param')){

var default_params = {
		'slide' :{'speed': 5000,},
		'general' : {'rowToShow': 10,'showEmpty': true,}
		};

var params = default_params;	
var dtTbl;
var apiUrl;
var inSlide = false, onSlide = 0, isStart = false;
var boxTimer;
var isDataSet = false;
var BigData = "";
var defaultDataColDef = [];
var defaultDataFilter = [];

var passObject = function() {
	
	this.init= function(url) {
  		//console.info('passObject initialized');
		apiUrl = url;
		return true;
	}
	
	this.getDefaultDataParams = function(defaultDataParams){
		//console.info("DefData_Params:33>", 	defaultDataParams);
		defaultDataFilter = defaultDataParams.default_filter;
		defaultDataColDef = defaultDataParams.default_colsDef;
		
		if(isDataSet == true && BigData!=""){
			getDataScreen(BigData,defaultDataFilter,defaultDataColDef);	
			isDataSet == false;
			BigData = "";
		}
	}
	
 	/* Called on ts:43|47:107:117*/
	this.updateData = function(data){
		//console.info("dashScreen:46>Data Updated",data,defaultDataFilter);
		if(defaultDataFilter.length>0){
			getDataScreen(data,defaultDataFilter,defaultDataColDef);
			isDataSet == false;
		}else{
			isDataSet = true;
			BigData	= data;
		}
	}
	 /* Called on ts:40 */
	this.showTime = function(){
		//console.info("Show the Time");
		showTime();	
	}
	
	/* Called on ts:91
	*Set the general params and initialize the slide,
	* get the slide content informations (Event Binded)
	* initialize the slide
	*/
	this.setgParams = function(gParams){
		
		if(gParams){
			//console.info(gParams);
			params = {
				'slide' :{
					'speed': gParams.speed * 1000,
					},
				'general' : {
					'rowToShow': parseInt(gParams.rowtoshow),
					'showEmpty': (gParams.showEmpty == 1)? true: false,
					}	
				};
		}else{
			params = default_params;
		}
		//console.info("general param is set:81>", params);
				
		$('#box-wrapper').carousel()
			.bind('slid.bs.carousel',function(e){
				let evtarget = $(this).find('.active');
				
				getMyInfo(evtarget);
			});
		//Initialize the slide
		isStart = true;
		goSlide();
		
	}
	 
	 /* Called on ts:52
	 reset on update */
	 this.resetImgs = function(){
		//console.warn("Images Reseted:98>");
		let imgBoxes = $(document).find('.imgbox');
			$(imgBoxes[0]).prev().addClass('active');
			imgBoxes.remove();
	}
	
	/*Called on ts:55|73:125:128*/
	this.setImgs = function(gImages){
		//console.info("gImages:106>",gImages);
		if(gImages.length>0){
			gImages.forEach(function(elem){
				let div = $('<div>',{'class':"box imgbox carousel-item",'title':elem.imgname,'data-icon':"fa fa-desktop mr-2"});
				div.append('<img src="'+apiUrl+'/../images/'+elem.imgsrc+'" class="dashimg">');
				div.appendTo($('#bx')); 
			});
			
		}
		let hasActive = $(document).find('.carousel-item.active');
			
		if(hasActive.length==0){
			let items = $(document).find('.carousel-item');
				$(items[0]).addClass('active');
		}
	}
	/*xDatas = [{"name": "xData",
		 datas:{...},
		 xFilters:[{"id":"someXData",
		 			"title":"Some X Data",
					"filter":null},
				  {...}],
		 xColDefs:{...}] */
		 
	this.extraData = function(xDatas){
		//console.info('big x Data', xDatas);
		
		for(let src of xDatas){
			let  xFilters = [{"id": (src.name).replace('/\s+/g',"_"),
		 			"title":src.name,
					"filter":null},
				  	];	
					
			let xData = src.datas;
			
				if(src.xFilters.length>0){
					xFilters = src.xFilters;	
				}
			let xColDef = src.colDef;
			
			//console.info("xData",src.datas, "XFilter", xFilters, "Col Def", xColDef);
			
			getDataScreen(src.datas, xFilters, xColDef);
		}
	}
} //end passObject

/******** For Slide{ *****/
	const goSlide = function(){
		
		boxTimer = setInterval(function(){
			if(inSlide){
				pageNext();
			}else{
				$('#box-wrapper').carousel('next');	
			}
			if(isStart){
				$(document).find('.box-holder').remove();
			}
		}, params.slide.speed);
	}

	
	function getMyInfo(obj){
		//console.info("getMy Info:140>",target);
		let target = $(obj);
		let ttl = target.attr('title');
		
		setCaption(ttl);
		
		let dtTbl = target.find('table.dataTable');
		
		let tbl = dtTbl.DataTable();
		//console.info(dtTbl);
		
		if(dtTbl.length>0){
			inSlide = true;
			onSlide = 0;
			
			tbl.page(0).draw();
			
			let infos = tbl.page.info();
			let totalRecords = infos.recordsDisplay;
			
			//console.info(infos);
			if(infos.pages <= 1 ){
				inSlide = false;
				onSlide = 0;
				setCaption(' ('+totalRecords+')', true);	
			}else{
				setCaption((infos.page+1)+'/'+infos.pages+' ('+totalRecords+')', true);
			}
		}
		if(target.attr('data-sname')){
			setCaption('<small class="d-block mt-3" style="text-transform: capitalize;">'+target.attr('data-sname')+'</small>', true,true);
		}
	}
	
	const pageNext = function(){
		
		let target = $(document).find('#box-wrapper .active');
		obj = $(target).find('table.dataTable');
		let tbl = obj.DataTable();
				
				
		let infos = tbl.page.info();
		let totalRecords = infos.recordsDisplay;
		//console.info(infos);
		
		tbl.page('next').draw('page');
			infos = tbl.page.info();
			
			setCaption((infos.page+1)+'/'+infos.pages+' ('+totalRecords+')', true);
		
		onSlide = infos.page;
		if(((infos.page+1) == infos.pages) || infos.pages <= 1){
			inSlide = false;
			onSlide	= 0;
		}
	}
	
	
	const setCaption = function(text,append = false,newLine = false){
		if(text!=""){
			let caption = document.querySelector('#boardCaption');
			if(append){
				if(newLine){
					$(caption).append('<br>'+text+'');
				}else{
					if(($(caption).find('span')).length>0){
						$(caption).find('span').text(' '+text);
					}else{
						$(caption).append(' <span>'+text+'</span>');
					}
				}
			}else{
				$(caption).text(text);
			}
		}		
	}
	
/****************}end for Slide******************/

/*get The Data and render data to Table, set filter and colums Definition */
function getDataScreen(data, DataFilter, colDefs = []){
		
		var bigData = [{'not':'loaded'}];

		 bigData = data;
		
		//console.info('DS:215>dts', data);

	let defaultClass = "table mat-elevation-z3 setScroll";
	
	let filterList = DataFilter;
	//console.info('dataFilter',filterList);
	 filterList.forEach(function(elem){
		 //console.info(elem.id,elem.title,elem.filter);
		 	let sName = (elem.sname)? elem.sname: "";
			setTbl(bigData,elem.id,elem.title,elem.filter,colDefs,defaultClass,sName); 
	 });
	 
		/*Create the Table Container for Slide, create the Datas to show on a Table
		/*@theData:(Array of objects) = the data
		/*@container:(String) = name of the container for slide, use too for the name of table
		/*@title:(String) = Title to be displayed on Slide;
		/*@filter:(objects) = use to filter theData and create another Page on slide {"cols": int,"value": any}
		/*@colsDef: (Array of objects) = Columns définition of the table 
			{"name": string,"title": string ,"class": string}, otherwise, use theData structure by défault
		/*@theClass: (string) the default Class to use on the table
		*/
		function setTbl(theData,container,title,filter,colsDef="",theClass,sTitle="") {

			let sCol = [];//Cols définition
			if(colsDef == "" || colsDef.length ==0){ //if no Cols définition provided
				//console.info("No Cols Defs", colsDef,"Data", theData);
				let dataRef = theData[0];
				
				for(let key in dataRef){
					let data = {};
					data.name = key;
					data.title = key;
					data.class = "";
					sCol.push(data);
				}
				
				
			}else{
				sCol = colsDef; 
			}
			
			let cArr = [];
			let tblTtl = {};
			let col = [];
			let dtTbl;
			//get Cols definitions and Columns Title if set
			for(let c of sCol){
				let colData = {};
				colData.data = c.name;
				if(c.class!=""){
					colData.class = c.class;	
				}
				cArr.push(colData);
				tblTtl[c.name] = c.title;
				col.push(c.name);
			}

			
			let myTableHeader = tblTtl;
			//console.info("Tab TTL",tblTtl);
			// the json data.
			const myTbl = theData;
			
			// Create table.
			const table = document.createElement("table");
			table.setAttribute('id','DataTable_'+container);
			
			if(theClass!=""){
				table.setAttribute('class',theClass);
			}
		
			// Create table header row using the extracted headers above.
			let thead = document.createElement('thead');
			//let tr = thead.insertRow(-1);                   // table row.
			let tr = document.createElement('tr');
		
			for (let i = 0; i < col.length; i++) {
			  let th = document.createElement("th");      // table header.
			  th.innerHTML = myTableHeader[col[i]];
			  tr.appendChild(th);
			}
			
				thead.appendChild(tr);
				
				table.appendChild(thead);
				
			let tbody = document.createElement('tbody');
				
			table.appendChild(tbody);
			
			//Set DataTable
			dtTbl = $(table).DataTable({
				"pageLength": params.general.rowToShow, 
				//"lengthMenu": [[2, 5,10, -1], [3, 5, 10, "All"]],
				"language": {
					"zeroRecords": "No Records",
       			 },
				"ordering": false,
				data: theData,
				"columns": cArr, /*[{ "data": string,"class":string },{...}] */
				"createdRow": function ( row, data, index ) {
					
						$('td.formated_dmh', row).html(function(e,v){return formatDate(v,"dmh");});
						$('td.formated_dmyh', row).html(function(e,v){return formatDate(v,"dmyh");});
						$('td.formated_sd', row).html(function(e,v){return formatDate(v,"sd");});
						$('td.formated_sdyy', row).html(function(e,v){return formatDate(v,"sdyy");});
						$('td.formated_sdyy.transform', row).html(function(e,v){return formatDate(v,"sdyy");});
						
						
						let clss = $('td.couleur', row).eq(0).text();
						//let clss = $('td.couleur',row).text();
						$('td.couleur', row).eq(0).parent('tr').addClass(clss);
						//$('td.couleur',row).parent('tr').addClass(clss);
						$('td.xcouleur',row).eq(0).attr('data-xcouleur',$('td.xCref',row).eq(0).text());
								//.append($('<span>',{"class":$('td.xCref',row).eq(0).text(),"title":$('td.xCref',row).eq(0).text()}));
        			}
				}); 
				//Make Filter if set
				if(filter){
					dtTbl.column(filter.col).search(filter.value).draw();
				}
				//Bind the drawn event
				dtTbl.on('draw', function () {
					$(table).find('td').not('.hideCol').text(function(index,text){
						let theClass = $(this).attr("data-xcouleur");
						if(theClass){
							$(this).html('<span class="'+theClass+'">'+text+'</span>');
						}else{
							$(this).html('<span>'+text+'</span>');
						}
					});
					
					
   				 });
				 //Re-drawn if the data is updated on slide
				 if(inSlide){
					dtTbl.page(onSlide).draw('page'); 
				 }
				 
			let infos = dtTbl.page.info();
			
			//Render and Add page if has records or showEmpty(general params) is true
			if(infos.recordsDisplay>0 || params.general.showEmpty){
				// Now, add the newly created table with json data, to a container.
				if(!($(document).find('.'+container)).length>0){
					let cItem = $('<div>',{'class':'box carousel-item '+container,'title':title});
					if(sTitle){
						cItem.attr('data-sname',sTitle);
					}
					cItem.append($('<div>',{'id': container,'class': 'box-sticky'}))
						 .appendTo($('#bx'));
				}
				
				let contentWrapper = $(document).find('#'+container); 
						//contentWrapper.innerHTML = "";
						//contentWrapper.appendChild(table);
					if(contentWrapper.length>0){
						//contentWrapper.innerHTML = "";
						contentWrapper.html("");
						//contentWrapper.appendChild(table);
						$(table).find('td').not('.hideCol').text(function(index,text){
						let theClass = $(this).attr("data-xcouleur");
						if(theClass){
							$(this).html('<span class="'+theClass+'">'+text+'</span>');
						}else{
							$(this).html('<span>'+text+'</span>');
						}
					});
						contentWrapper.append(table);
					}
			}//end if infos
			
			function formatDate(theDate, format="",transform = false){
				if(transform){
					theDate = theDate.slice(0,4)+"-"+theDate.slice(4,6)+"-"+theDate.slice(-2);
				}
				
				let dt =  new Date(theDate);
				
				if((dt.toString()).includes('Invalid')){ 
					return null;
				}
				
				let d = parseInt(dt.getDate());
				let M = parseInt(dt.getMonth())+1;
				let YYYY = parseInt(dt.getFullYear());
				let YY = YYYY.toString();
				YY = YY.slice(-2);
				let h = parseInt(dt.getHours());
				let m = parseInt(dt.getMinutes());
				
				d = (d<10)? "0"+d :d;
				M = (M<10)? "0"+ M : M;
				h = (h<10)? "0"+h : h;
				m = (m<10)? "0"+m : m;
				if(format == "dmh"){
					return `${d}-${M} ${h}:${m}`;
				}
				
				if(format == "dmyh"){
					return `${d}-${M}-${YY} ${h}:${m}`;
				}
				if(format == "sd"){
					return `${d}/${M}/${YYYY}`;
				}
				if(format == "sdyy"){
					return `${d}/${M}/${YY}`;
				}
				
			}
		  }// end SetTbl
		 
} //end getDataScreen

}// end if Hash


